import { createSelector } from "reselect"

const authState = state => state.auth

const location$ = createSelector([authState], state => state.location)

export const redirectTo$ = createSelector(
  [location$],
  state => state.redirectTo
)
