import "react-datepicker/dist/react-datepicker.css"

import React, { useEffect, useState } from "react"

import DatePicker from "react-datepicker"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Select from "react-select"
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import get from "lodash/get"
import moment from "moment"
import { snakeify } from "../../common/utils"
import { useForm } from "react-hook-form"

const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
  { value: "Rather not say", label: "Rather not say" },
]

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    color: "#4a5568",
    borderColor: state.isFocused ? "#667eea" : "#edf2f7",
    backgroundColor: "#FFF",
    boxShadow: "none",
    borderWidth: 2,
    borderRadius: 0,
    "&:hover": {
      borderColor: state.isFocused ? "#667eea" : "#edf2f7",
    },
    paddingLeft: "6px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#4a5568",
  }),
}

const Label = ({ label, htmlFor }) => (
  <div className="md:w-1/3">
    <label
      className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
      htmlFor={htmlFor}
    >
      {label}
    </label>
  </div>
)

const Details = ({ user, updateUserStarted, submitting }) => {
  const dobDate = new Date(user.dateOfBirth)
  const genderOption = user.gender
    ? {
        value: user.gender,
        label: user.gender,
      }
    : genderOptions[-1]

  const { handleSubmit, register, setValue } = useForm({
    defaultValues: {
      ...user,
      dateOfBirth: dobDate, // convert the server date string to a date object
      gender: genderOption,
    },
    mode: "onSubmit",
  })

  const [subscribed, setSubscribed] = useState(user.subscribed)
  const [dateOfBirth, setDateOfBirth] = useState(dobDate)
  const [gender, setGender] = useState(genderOption)

  const handleGenderChange = selected => {
    setValue("gender", selected)
    setGender(selected)
  }

  const handleDateOfBirthChange = date => {
    setValue("dateOfBirth", date)
    setDateOfBirth(date)
  }

  const handleSubscribedChange = data => {
    setValue("subscribed", data.target.checked)
    setSubscribed(data.target.checked)
  }

  useEffect(() => {
    register({ name: "dateOfBirth" })
    register({ name: "gender" })
  }, [])

  const onSubmit = values => {
    if (!!values) {
      const userValues = snakeify({
        ...values,
        gender: get(gender, "value"),
        dateOfBirth: moment(dateOfBirth).format("YYYY-MM-DD"), // convert back to server string
      })

      updateUserStarted({ user: userValues })
    }
  }

  return (
    <div className="flex flex-col items-center justify-center md:justify-start w-full">
      <div className="w-full max-w-lg px-6 md:px-0 md:mx-auto">
        <div className="md:flex md:items-center mb-6">
          <h2 className="mt-2 text-xl md:px-4 font-medium text-gray-600 w-1/3 text-left md:text-right">
            Details
          </h2>
        </div>
      </div>

      <form
        className="w-full max-w-lg md:px-0 md:mx-auto"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="md:flex md:items-center mb-6">
          <Label label="First Name" htmlFor="inline-first-name" />

          <div className="md:w-2/3">
            <input
              className="bg-white appearance-none border-2 border-gray-200 w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
              id="inline-first-name"
              type="text"
              name="firstName"
              ref={register({
                required: "Required",
                message: "First name is required",
              })}
            />
          </div>
        </div>
        <div className="md:flex md:items-center mb-6">
          <Label label="Last Name" htmlFor="inline-last-name" />
          <div className="md:w-2/3">
            <input
              className="bg-white appearance-none border-2 border-gray-200 w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
              id="inline-last-name"
              type="text"
              name="lastName"
              ref={register({
                required: "Required",
                message: "Last name is required",
              })}
            />
          </div>
        </div>
        <div className="md:flex md:items-center mb-6">
          <Label label="Email" htmlFor="inline-email" />
          <div className="md:w-2/3">
            <input
              className="bg-white appearance-none border-2 border-gray-200 w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 rounded-none"
              id="inline-email"
              name="email"
              ref={register({
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              })}
            />
          </div>
        </div>
        <div className="md:flex md:items-center mb-6">
          <Label label="Gender" htmlFor="inline-gender" />
          <div className="md:w-2/3">
            <Select
              id="inline-gender"
              options={genderOptions}
              value={gender}
              onChange={handleGenderChange}
              styles={customStyles}
            />
          </div>
        </div>
        <div className="md:flex md:items-center mb-6">
          <Label label="Birthday" htmlFor="inline-birthday" />
          <div className="md:w-2/3">
            <DatePicker
              id="inline-birthday"
              dateFormat="dd/MM/yyy"
              maxDate={new Date()}
              showYearDropdown={true}
              scrollableYearDropdown={true}
              yearDropdownItemNumber={80}
              className="w-full"
              selected={dateOfBirth}
              onChange={date => handleDateOfBirthChange(date)}
              customInput={
                <input className="bg-white appearance-none border-2 border-gray-200 w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 rounded-none" />
              }
            />
          </div>
        </div>
        <div className="md:flex md:items-center mb-6">
          <Label label="Postcode" htmlFor="inline-postcode" />
          <div className="md:w-2/3">
            <input
              className="bg-white appearance-none border-2 border-gray-200 w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
              id="inline-postcode"
              type="text"
              name="postcode"
              ref={register({
                pattern: {
                  value: /^[0-9]{4}$/,
                  message: "Invalid postcode",
                },
              })}
            />
          </div>
        </div>
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3" />
          <label className="md:w-2/3 block text-gray-500 font-bold">
            <input
              className="mr-2 leading-tight"
              type="checkbox"
              id="inline-subscribed"
              name="subscribed"
              onChange={handleSubscribedChange}
              value={subscribed}
              ref={register}
            />
            <span className="text-sm">Send me your newsletter!</span>
          </label>
        </div>
        <div className="md:flex md:items-center">
          <div className="md:w-1/3"></div>
          <div className="md:w-2/3">
            {submitting ? (
              <div className="w-24 border p-2 hover:bg-white font-light text-center bg-white">
                <FontAwesomeIcon icon={faCircleNotch} spin={true} />
              </div>
            ) : (
              <button
                type="submit"
                disabled={submitting}
                className="w-24 border p-2 hover:bg-white font-light text-center bg-white focus:outline-none"
              >
                Update
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

export default Details
