import BrandAnalytics, { EVENTS } from "../../brands/analytics"
import { brandOffers$, walletItemBrand$ } from "../store/selectors"
import {
  createWalletItemStarted,
  removeWalletItemStarted,
} from "../store/actions/actionCreators"
import {
  useDisabledIntercomEffect,
  useTrackEventEffect,
} from "../../common/effects"

import { AnalyticsContext } from "../../common/utils"
import BrandDetails from "../../brands/components/BrandDetails"
import EntitiesContainer from "../../common/components/EntitiesContainer"
import Layout from "../../layout/components/Layout"
import { OfferContext } from "../../offers/utils"
import OfferList from "../../offers/components/OfferList"
import React from "react"
import SEO from "../../layout/components/SEO"
import { connect } from "react-redux"
import get from "lodash/get"
import { graphql } from "gatsby"
import { isUpdatingWalletItemLoading$ } from "../../walletItems/store/selectors/loadingSelectors"

const offerContextValue = {
  showBrandImage: false,
}

function WalletItem({
  brand,
  brandOffers,
  location,
  createWalletItemStarted,
  removeWalletItemStarted,
  submitting,
  isWalletItem,
}) {
  const { uuid, name, card_asset_image, description } = brand
  const analytics = BrandAnalytics.brandProperties(name)
  const brandImage = get(card_asset_image, "medium", null)

  useTrackEventEffect(EVENTS.VIEW_BRAND, analytics)
  useDisabledIntercomEffect()

  return (
    <AnalyticsContext.Provider value={analytics}>
      <Layout>
        <SEO
          title={name}
          description="loyalty brands whatsmine"
          image={brandImage}
        />
        <EntitiesContainer>
          <div className="flex flex-col w-full mx-auto md:mt-24">
            <BrandDetails
              name={name}
              image={brandImage}
              title={name}
              uuid={uuid}
              description={description}
              createWalletItem={createWalletItemStarted}
              removeWalletItem={removeWalletItemStarted}
              submitting={submitting}
              isWalletItem={isWalletItem}
            />
            {brandOffers.length ? (
              <div className="flex flex-row w-full justify-center mt-10 mx-auto">
                <OfferContext.Provider value={offerContextValue}>
                  <OfferList entities={brandOffers} />
                </OfferContext.Provider>
              </div>
            ) : null}
          </div>
        </EntitiesContainer>
      </Layout>
    </AnalyticsContext.Provider>
  )
}

const mapStateToProps = (state, props) => ({
  submitting: isUpdatingWalletItemLoading$(state),
})

const mapDispatchToProps = {
  removeWalletItemStarted,
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletItem)
