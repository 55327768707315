import * as selectors from "../../auth/store/selectors/token-selector"

import EntitiesContainer from "../../common/components/EntitiesContainer"
import React from "react"
import SignInToContinue from "../../auth/components/SignInToContinue"
import { connect } from "react-redux"

function PrivateRoute(props) {
  const { component: Component, location, isAuthenticated, ...rest } = props

  if (!isAuthenticated) {
    return (
      <EntitiesContainer>
        <div className="flex flex-col mx-2 justify-start items-center">
          <div className="flex-row">
            <div className="text-xl mt-0 mb-6">
              You don't have access to this page
            </div>
          </div>

          <SignInToContinue redirectTo={location.pathname} />
        </div>
      </EntitiesContainer>
    )
  }
  return <Component {...rest} />
}

const mapStateToProps = state => ({
  isAuthenticated: selectors.isSignedIn$(state),
})

export default connect(mapStateToProps)(PrivateRoute)
