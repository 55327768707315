import React, { useEffect } from "react"

import HeroContainer from "../../common/components/HeroContainer"
import Layout from "../../layout/components/Layout"
import Loading from "../../common/components/Loading"
import { connect } from "react-redux"
import { signOutStarted } from "../store/actions/actionCreators"
import { StyledHeroTitle } from "../../common/components/styled/hero"

function SignOut({ signOutStarted }) {
  useEffect(() => {
    signOutStarted()
    return () => {}
  }, [])

  return (
    <Layout checkAuth={false}>
      <HeroContainer>
        <StyledHeroTitle>Signing out...</StyledHeroTitle>
      </HeroContainer>
      <Loading text="We miss you already. Come back soon." />
    </Layout>
  )
}

export default connect(null, {
  signOutStarted,
})(SignOut)
