import * as actionCreators from "../store/actions/actionCreators"
import * as selectors from "../store/selectors/loadingSelectors"
import * as tokenSelector from "../store/selectors/token-selector"

import { BuildContext, RouteInfo } from "../../common/utils"
import React, { useContext, useEffect } from "react"

import EntitiesContainer from "../../common/components/EntitiesContainer"
import Layout from "../../layout/components/Layout"
import { Link } from "gatsby"
import SignInContainer from "../components/SignInContainer"
import { connect } from "react-redux"
import get from "lodash/get"
import { navigate } from "gatsby"
import { useDevelopmentEffect } from "../../common/effects"
import { useForm } from "react-hook-form"

const SignInForm = ({
  submitting,
  isAuthenticated,
  location,
  addRedirectTo,
  signInStarted,
}) => {
  const { isProduction } = useContext(BuildContext)
  useDevelopmentEffect(isProduction)

  // If we're arrive here from the SignInCodeForm ("no code" flow)
  // the user email will be available in the location state
  const email = get(location, "state.email", "")

  useEffect(() => {
    if (isAuthenticated) {
      navigate(RouteInfo.HOME, { replace: true })
    }

    // If we're arrive here from a screen that we want to go back to
    // set the redirectTo and the Callback.js will navigate to it on
    // successful sign in
    const redirectTo = get(location, "state.redirectTo")
    if (redirectTo) {
      addRedirectTo(redirectTo)
    }
  }, [])

  const { handleSubmit, register, errors } = useForm({
    mode: "onSubmit",
    defaultValues: { email },
  })

  const onSubmit = values => {
    if (!!values.email) {
      // passwordlessStart(values.email, signInCallback)
      signInStarted(values.email)
    }
  }

  return (
    <Layout>
      <EntitiesContainer>
        <SignInContainer
          title={`Enter your email to sign in or create an account`}
          buttonText="Submit"
          submitting={submitting}
          errors={errors.email && errors.email.message}
          handleSubmit={handleSubmit(onSubmit)}
          showSocialButtons={true}
          footer={() => {
            return (
              <>
                By signing up, you agree to our&nbsp;
                <Link to="/collection-statement" className="underline">
                  Collection Statement
                </Link>
                ,&nbsp;
                <Link to="/privacy" className="underline">
                  Privacy Policy
                </Link>
                &nbsp;and&nbsp;
                <Link to="/termsofservice" className="underline">
                  Terms of Service
                </Link>
              </>
            )
          }}
        >
          <input
            name="email"
            placeholder="Email"
            className="font-base border p-2 outline-none w-64 md:w-full"
            ref={register({
              required: "Required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address",
              },
            })}
          />
        </SignInContainer>
      </EntitiesContainer>
    </Layout>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: tokenSelector.isSignedIn$(state),
  submitting: selectors.isSignInLoading$(state),
})

const mapDispatchToProps = {
  signInStarted: actionCreators.signInStarted,
  addRedirectTo: actionCreators.addRedirectTo,
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm)
