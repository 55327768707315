import Account from "../accounts/templates/Account"
import Callback from "../auth/screens/Callback"
import PrivateRoute from "../common/components/PrivateRoute"
import React from "react"
import { Router } from "@reach/router"
import SignInCodeForm from "../auth/screens/SignInCodeForm"
import SignInForm from "../auth/screens/SignInForm"
import SignOut from "../auth/screens/SignOut"
import Wallet from "../walletItems/templates/Wallet"
import WalletItem from "../walletItems/templates/WalletItem"
import appRoutes from "../common/utils/appRoutes"

const App = () => {
  return (
    <Router>
      <PrivateRoute path={appRoutes.ACCOUNT} component={Account} />
      <PrivateRoute path={appRoutes.WALLET} component={Wallet} />
      <PrivateRoute path={`${appRoutes.WALLET}/:id`} component={WalletItem} />
      <SignOut path={appRoutes.SIGN_OUT} />
      <SignInForm path={appRoutes.SIGN_IN} />
      <SignInCodeForm path={appRoutes.SIGN_IN_CODE} />
      <Callback path={appRoutes.CALLBACK} />
    </Router>
  )
}
export default App
