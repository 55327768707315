import * as searchSelectors from "../../search/store/selectors"
import * as selectors from "../../auth/store/selectors/token-selector"
import * as walletItemSelectors from "../../walletItems/store/selectors"

import {
  ENTITY_TYPES,
  WALLET_ITEM_FILTER_TYPES,
  entityFilterlabel,
  serializeFilterToQueryString,
} from "../../common/utils"
import React, { useEffect, useState } from "react"
import {
  addWalletItemsPageInfo,
  loadWalletItemsStarted,
} from "../../walletItems/store/actions/actionCreators"
import {
  useDisabledIntercomEffect,
  useSearchResultEffect,
} from "../../common/effects"

import BrandList from "../../brands/components/BrandList"
import EntitiesContainer from "../../common/components/EntitiesContainer"
import Layout from "../../layout/components/Layout"
import Loading from "../../common/components/Loading"
import NoResults from "../../common/components/NoResults"
import SEO from "../../layout/components/SEO"
import SearchableItems from "../../search/components/SearchableItems"
import WalletItemsList from "../components/WalletItemsList"
import { connect } from "react-redux"
import { isLoadWalletItemsLoading$ } from "../store/selectors/loadingSelectors"
import { loadMore } from "../../common/utils/pagination"

function WalletItemsTemplate({
  location,
  isAuthenticated,
  filter,
  pageInfo,
  loading,
  canLoadMore,
  searchResults,
  searchResultsCount,
  addFilter,
  loadWalletItemsStarted,
  addWalletItemPageInfo,
}) {
  const [results, setResults] = useState(searchResults)

  useDisabledIntercomEffect()
  useSearchResultEffect(setResults, searchResults)

  useEffect(() => {
    loadWalletItemsStarted()
  }, [])

  const handleLoadMore = () =>
    loadMore(filter, pageInfo, ENTITY_TYPES.WALLET_ITEMS, addWalletItemPageInfo)

  return (
    <Layout>
      <SEO
        title="Wallet"
        description="loyalty offers rewards walletItems whatsmine digital wallet"
        url={window.location.href}
      />

      <SearchableItems
        title={entityFilterlabel(ENTITY_TYPES.WALLET_ITEMS, filter)}
        resultCount={searchResultsCount}
        canLoadMore={canLoadMore}
        loadMore={handleLoadMore}
        entityType={ENTITY_TYPES.WALLET_ITEMS}
        disableFilter={true}
      >
        <EntitiesContainer>
          {results.length ? (
            <WalletItemsList entities={results} />
          ) : loading ? (
            <Loading text="Loading wallet items..." />
          ) : (
            <NoResults />
          )}
        </EntitiesContainer>
      </SearchableItems>
    </Layout>
  )
}

const mapStateToProps = (state, props) => ({
  pageInfo: walletItemSelectors.filteredPageInfo$(state),
  loading: isLoadWalletItemsLoading$(state),
  canLoadMore: walletItemSelectors.canLoadMore$(state),
  searchResults: walletItemSelectors.paginatedSearchResults$(state),
  searchResultsCount: walletItemSelectors.searchResultsCount$(state),
})

const mapDispatchToProps = {
  loadWalletItemsStarted,
  addWalletItemsPageInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletItemsTemplate)
