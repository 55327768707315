import * as actionCreators from "../store/actions/actionCreators"
import * as selectors from "../store/selectors/loadingSelectors"

import React, { useContext } from "react"

import { BuildContext } from "../../common/utils"
import EntitiesContainer from "../../common/components/EntitiesContainer"
import Layout from "../../layout/components/Layout"
import SignInContainer from "../components/SignInContainer"
import appRoutes from "../../common/utils/appRoutes"
import { connect } from "react-redux"
import get from "lodash/get"
import { navigate } from "gatsby"
import { useDevelopmentEffect } from "../../common/effects"
import { useForm } from "react-hook-form"

const SignInCodeForm = ({
  submitting,
  signInConfirmationStarted,
  location,
}) => {
  const { isProduction } = useContext(BuildContext)
  useDevelopmentEffect(isProduction)

  const { handleSubmit, register, errors } = useForm()

  const email = get(location, "state.email")
  if (!email) {
    navigate(appRoutes.SIGN_IN, { replace: true })
  }

  const onSubmit = values => {
    if (!!values.code) {
      signInConfirmationStarted({ email, code: values.code })
    }
  }

  const handleNoCode = () => {
    navigate(appRoutes.SIGN_IN, { replace: true, state: { email } })
  }

  return (
    <Layout>
      <EntitiesContainer>
        <SignInContainer
          title={`Check your inbox we've sent you a code to ${email}`}
          buttonText="Sign in"
          submitting={submitting}
          errors={errors.code && errors.code.message}
          handleSubmit={handleSubmit(onSubmit)}
          footer={() => {
            return (
              <span onClick={handleNoCode} className="underline">
                Did not get the code?
              </span>
            )
          }}
        >
          <input
            name="code"
            placeholder="Code"
            autoFocus
            className="font-base border p-2 outline-none w-64 md:w-full"
            ref={register({
              required: "Required",
              message: "Code is required",
            })}
          />
        </SignInContainer>
      </EntitiesContainer>
    </Layout>
  )
}

const mapStateToProps = state => ({
  submitting: selectors.isSignInFormLoading$(state),
})

const mapDispatchToProps = {
  signInConfirmationStarted: actionCreators.signInConfirmationStarted,
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInCodeForm)
