import AnimatedCard from "../../common/components/AnimatedCard"
import ImageWithPlaceholder from "../../brands/components/ImageWithPlaceholder"
import { Link } from "gatsby"
import React from "react"
import ResponsiveCard from "../../common/components/ResponsiveCard"
import appRoutes from "../../common/utils/appRoutes"
import path from "path"
import { slugifyBrand } from "../../common/utils"

const Card = ({ item }) => {
  const { uuid, card_asset_image, name } = item
  const brandPath = path.join(appRoutes.WALLET, slugifyBrand(item))

  return (
    <ResponsiveCard
      key={uuid}
      className="flex w-1/2 md:w-1/3 xl:w-1/4 justify-center"
    >
      <AnimatedCard className="flex max-w-sm shadow-lg bg-gray-300 m-2 w-full border border-gray-400">
        <Link to={brandPath} className="flex flex-col w-full">
          <ImageWithPlaceholder
            src={card_asset_image ? card_asset_image.medium : null}
            name={name}
          />
        </Link>
      </AnimatedCard>
    </ResponsiveCard>
  )
}

export default Card
