import { createSelector } from "reselect"
import get from "lodash/get"

const loadingState = state => state.loading

const loadingActions = {
  UPDATE_USER: "UPDATE_USER",
  LOAD_USER: "LOAD_USER",
  CREATE_USER: "CREATE_USER",
}

export const isUpdateUserLoading$ = createSelector([loadingState], state =>
  get(state, loadingActions.UPDATE_USER)
)

export const isLoadUserLoading$ = createSelector(
  [loadingState],
  state =>
    get(state, loadingActions.LOAD_USER) ||
    get(state, loadingActions.CREATE_USER)
)
