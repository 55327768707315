import React, { useState } from "react"
import { authorizeWithFacebook, authorizeWithGoogle } from "../utils"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import fbButton from "../../images/fb-button.png"
import googleButton from "../../images/btn_google_light_normal_ios.svg"
import logo from "../../images/logo_colour_background.svg"

function SocialSigninButton({
  onClick,
  provider,
  buttonSrc,
  buttonText,
  buttonStyle,
  buttonTextStyle,
  buttonImageStyle,
  disabled,
}) {
  return (
    <button
      disabled={disabled}
      style={buttonStyle}
      className="flex items-center border rounded-sm cursor-pointer w-64 focus:outline-none hover:border-gray-600"
      onClick={onClick}
    >
      <div style={buttonImageStyle}>
        <img src={buttonSrc} alt={provider} className="shadow-none" />
      </div>
      <div style={buttonTextStyle} className="flex items-center">
        {buttonText}
      </div>
    </button>
  )
}

const SignInContainer = ({
  title,
  buttonText,
  submitting,
  errors,
  handleSubmit,
  children,
  footer,
  showSocialButtons,
}) => {
  const [socialSubmitting, setSocialSubmitting] = useState(false)

  const handleGoogleClicked = () => {
    setSocialSubmitting(true)
    authorizeWithGoogle()
  }

  const handleFacebookClicked = () => {
    setSocialSubmitting(true)
    authorizeWithFacebook()
  }

  return (
    <div className="flex flex-col border justify-center items-center w-full md:w-2/3 mx-auto bg-white opacity-95 py-12 mt-10">
      <div className="mb-12">
        <img
          src={logo}
          alt="WhatsMine Logo"
          style={{ width: 100, height: 100 }}
          className="rounded-full shadow-lg"
        />
      </div>
      <div className="w-full">
        <div className="text-xl font-medium text-gray-600 mb-4 w-2/3 text-center leading-snug mx-auto">
          {title}
        </div>

        <form onSubmit={handleSubmit} className="w-full lg:w-1/2 mx-auto">
          <div className="flex flex-col md:flex-row py-4 mx-6 items-center justify-center">
            <div className="flex flex-row md:flex-col pb-4 flex-grow">
              <div className="flex-col md:flex-row md:placeholder-blue-500">
                {children}
              </div>
            </div>
            <div className="flex flex-row md:flex-col md:ml-2 pb-4">
              {submitting ? (
                <div className="w-64 md:w-24 border p-2 hover:bg-gray-200 font-light text-center bg-white">
                  <FontAwesomeIcon icon={faCircleNotch} spin={true} />
                </div>
              ) : (
                <button
                  type="submit"
                  disabled={submitting}
                  className="w-64 md:w-24 border p-2 hover:bg-gray-200 font-light text-center bg-white focus:outline-none"
                >
                  {buttonText}
                </button>
              )}
            </div>
          </div>
          {showSocialButtons ? (
            <>
              <div className="flex flex-row w-full justify-center">OR</div>
              <div className="flex flex-row w-full justify-center py-4">
                <SocialSigninButton
                  disabled={submitting || socialSubmitting}
                  onClick={handleGoogleClicked}
                  provider="google"
                  buttonSrc={googleButton}
                  buttonText="Sign in with Google"
                  buttonStyle={{
                    height: 48,
                  }}
                  buttonTextStyle={{ paddingLeft: 8, paddingRight: 16 }}
                />
              </div>
              {process.env.GATSBY_FACEBOOK_SIGNIN_ENABLED ? (
                <div className="flex flex-row w-full justify-center pb-6">
                  <SocialSigninButton
                    disabled={submitting || socialSubmitting}
                    onClick={handleFacebookClicked}
                    provider="facebook"
                    buttonSrc={fbButton}
                    buttonImageStyle={{
                      width: 40,
                      marginTop: 8,
                      marginLeft: 12,
                      marginBottom: 8,
                      marginRight: 5,
                    }}
                    buttonStyle={{
                      height: 48,
                      backgroundColor: "#4267b2",
                      color: "#FFF",
                    }}
                    buttonText="Sign in with Facebook"
                    buttonTextStyle={{ height: 48, paddingRight: 16 }}
                  />
                </div>
              ) : null}
            </>
          ) : null}
        </form>

        <div className="w-2/3 lg:w-1/2 text-gray-600 text-center mx-auto py-6">
          {footer()}
        </div>
      </div>
    </div>
  )
}

export default SignInContainer
