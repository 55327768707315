import Card from "./Card"
import React from "react"

function WalletItemList({ entities }) {
  return (
    <div className="flex flex-wrap w-full justify-center">
      {entities
        .filter(item => item.id !== "dummy")
        .map(item => (
          <Card key={item.uuid} item={item} />
        ))}
    </div>
  )
}

WalletItemList.defaultProps = {
  entities: [],
}

export default WalletItemList
