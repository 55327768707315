import { Link } from "gatsby"
import React from "react"
import appRoutes from "../../common/utils/appRoutes"

function SignInToContinue({ redirectTo }) {
  return (
    <Link
      to={appRoutes.SIGN_IN}
      state={{ redirectTo }}
      className="w-64 mb-4 border p-2 hover:bg-gray-200 font-semibold text-center bg-white"
    >
      Sign in to continue
    </Link>
  )
}

export default SignInToContinue
