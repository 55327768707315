import * as actionCreators from "../../auth/store/actions/actionCreators"
import * as selectors from "../../auth/store/selectors/auth-selector"

import React, { useContext, useEffect } from "react"

import { BuildContext } from "../../common/utils"
import HeroContainer from "../../common/components/HeroContainer"
import Layout from "../../layout/components/Layout"
import Loading from "../../common/components/Loading"
import { StyledHeroTitle } from "../../common/components/styled/hero"
import { connect } from "react-redux"
import { useDevelopmentEffect } from "../../common/effects"

const Callback = ({ completeSignInStarted, redirectTo }) => {
  const { isProduction } = useContext(BuildContext)
  useDevelopmentEffect(isProduction)

  useEffect(() => {
    completeSignInStarted(redirectTo)
  }, [])

  return (
    <Layout checkAuth={false}>
      <HeroContainer>
        <StyledHeroTitle>Signing you in...</StyledHeroTitle>
      </HeroContainer>
      <Loading text="We're happy you're here." />
    </Layout>
  )
}

const mapStateToProps = state => ({
  redirectTo: selectors.redirectTo$(state),
})

const mapDispatchToProps = {
  completeSignInStarted: actionCreators.completeSignInStarted,
}

export default connect(mapStateToProps, mapDispatchToProps)(Callback)
