import { createSelector } from "reselect"
import get from "lodash/get"

const loadingState = state => state.loading

// returns true only when all actions is not loading
// export const isLoading$ = actions => state =>
//   some(actions, action => get(state, `loading.${action}`))

const loadingActions = {
  SIGN_IN: "SIGN_IN",
  SIGN_IN_CONFIRMATION: "SIGN_IN_CONFIRMATION",
}

export const isSignInLoading$ = createSelector([loadingState], state =>
  get(state, loadingActions.SIGN_IN)
)

export const isSignInFormLoading$ = createSelector([loadingState], state =>
  get(state, loadingActions.SIGN_IN_CONFIRMATION)
)
