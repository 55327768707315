import {
  DEFAULT_PAGE_SIZE,
  WALLET_ITEM_FILTER_TYPES,
} from "../../../common/utils"
import { filter$, search$ } from "../../../search/store/selectors"

import { createSelector } from "reselect"
import { offerEntities$ } from "../../../offers/store/selectors"

const entitiesState = state => state.entities
const walletItemsState = state => state.walletItems
const pageInfoState = state => state.pageInfo

const defaultPageInfo = {
  offset: 0,
  count: 0,
}

export const selectedId$ = (_, props) => props.id

export const walletItems$ = createSelector(
  [walletItemsState],
  state => state.walletWalletItems
)

export const walletItemEntities$ = createSelector(
  [entitiesState],
  state => state.walletItems
)

export const brandEntities$ = createSelector(
  [entitiesState],
  state => state.brands
)

export const walletItemsArray$ = createSelector([walletItemEntities$], state =>
  Object.values(state)
)

export const paginatedSearchResults$ = createSelector(
  [walletItemsArray$],
  state => state
)

export const searchResultsCount$ = createSelector(
  [walletItemsArray$],
  state => state.length
)

const pageInfo$ = createSelector([pageInfoState], state => state.walletItems)
export const filteredPageInfo$ = createSelector(
  [filter$, pageInfo$],
  (filter, pageInfo) => ({
    ...defaultPageInfo,
    ...pageInfo[WALLET_ITEM_FILTER_TYPES.ALL],
  })
)

export const canLoadMore$ = createSelector(
  [filteredPageInfo$, searchResultsCount$],
  (pageInfo, searchResultCount) =>
    pageInfo.offset + DEFAULT_PAGE_SIZE < searchResultCount
)

export const walletItemBrand$ = createSelector(
  [walletItemEntities$, selectedId$],
  (walletItems, selectedId) => {
    return walletItems[selectedId]
  }
)

export const brandOffers$ = createSelector(
  [walletItemBrand$, offerEntities$],
  (brand, offers) =>
    offers.filter(offer => {
      return offer.program === brand.uuid
    })
)
