import "react-datepicker/dist/react-datepicker.css"

import * as actionCreators from "../store/actions/actionCreators"
import * as userSelectors from "../store/selectors/userSelectors"

import React, { useContext, useEffect, useState } from "react"
import {
  isLoadUserLoading$,
  isUpdateUserLoading$,
} from "../store/selectors/loadingSelectors"

import { BuildContext } from "../../common/utils"
import Details from "../components/Details"
import EntitiesContainer from "../../common/components/EntitiesContainer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "../../layout/components/Layout"
import { Link } from "gatsby"
import Loading from "../../common/components/Loading"
import { connect } from "react-redux"
import { faSignOut as farSignout } from "@fortawesome/pro-regular-svg-icons"
import { useDevelopmentEffect } from "../../common/effects"

const Account = ({
  loading,
  submitting,
  user,
  loadUserStarted,
  updateUserStarted,
}) => {
  const [signOutButton, setSignOutButton] = useState(
    "If you really must sign out"
  )
  const { isProduction } = useContext(BuildContext)
  useDevelopmentEffect(isProduction)

  // load the user
  useEffect(() => {
    loadUserStarted({ email: user.email })
  }, [])

  const onMouseover = () => {
    setSignOutButton("Oh no please don't leave!")
  }

  const onMouseout = () => {
    setSignOutButton("If you really must sign out")
  }

  const shouldShowLoading = !user && loading

  return (
    <Layout loadUserOnCheckAuth={true}>
      <EntitiesContainer>
        <div className="flex flex-row border items-center justify-center w-full lg:w-1/2 mx-auto bg-white opacity-95 p-6 mt-10">
          {shouldShowLoading ? (
            <Loading />
          ) : (
            <Details
              user={user}
              submitting={submitting}
              updateUserStarted={updateUserStarted}
            />
          )}
        </div>
        <div className="flex flex-row border items-center w-full lg:w-1/2 mx-auto bg-white opacity-95 p-6 mt-10">
          <div className="flex flex-col items-left md:items-center justify-center md:justify-start w-full md:px-0 md:mx-auto">
            <Link
              onMouseEnter={onMouseover}
              onMouseLeave={onMouseout}
              to="/app/signout"
              className="w-full md:w-64 border p-2 hover:bg-gray-200 font-light text-center bg-white"
            >
              {signOutButton}
              <FontAwesomeIcon icon={farSignout} className="ml-2" />
            </Link>
          </div>
        </div>
      </EntitiesContainer>
    </Layout>
  )
}

export default connect(
  state => ({
    user: userSelectors.user$(state),
    submitting: isUpdateUserLoading$(state),
    loading: isLoadUserLoading$(state),
  }),
  {
    updateUserStarted: actionCreators.updateUserStarted,
    loadUserStarted: actionCreators.loadUserStarted,
  }
)(Account)
